<template>
	<div class="flex px-5 py-4 lg:py-3">
		<img
			class="h-[60px] w-[60px] object-cover lg:h-[125px] lg:w-[125px]"
			:src="producto?.imagen_miniatura ?? producto.imagen"
			:alt="producto.nombre"
             aria-hidden="true"
             style="font-size: 10px;"
             >
		<div class="w-full px-2">		
				<NuxtLink
					class="me-5 line-clamp-2 text-start text-[12px] text-[#525252] lg:line-clamp-3 lg:text-[14px] lg:font-normal"
					:to="`/catalogo/${producto.codigo}-${convertToUrlFriendly(producto.nombre)}`"
				>
					{{ producto.nombre }}
				</NuxtLink>
			<div class="flex pb-0 text-[10px] font-medium text-[#000]">
				<div class="me-auto" :class="{'mt-1':producto?.sugerencia}">(SKU: {{ producto.codigo }}) <span v-if="producto?.sugerencia" class="text-light-success ms-1 p-1">Sugerencia</span></div>
				<span
					class="me-4 lg:me-6 text-[12px] font-medium lg:text-[16px] lg:font-medium whitespace-nowrap"
					v-html="formatPriceSup(precioProducto)"
				/>
				<div>
					<svg
						id="icon-trash"
						class="me-0 cursor-pointer"
						xmlns="http://www.w3.org/2000/svg"
						width="17"
						height="18"
						viewBox="0 0 17 18"
						fill="none"
						@click="$emit('btnEliminarProducto', producto)"
					>
						<path
							d="M1 3.82353H16M5.6875 1H11.3125M6.625 13.2353V7.58824M10.375 13.2353V7.58824M11.7813 17H5.21875C4.18322 17 3.34375 16.1572 3.34375 15.1176L2.91569 4.80389C2.8935 4.26918 3.3193 3.82353 3.85238 3.82353H13.1476C13.6807 3.82353 14.1065 4.26918 14.0843 4.80389L13.6563 15.1176C13.6563 16.1572 12.8168 17 11.7813 17Z"
							stroke="#EE0404"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>

			<div 
                v-if="!producto?.sugerencia"
                class="flex w-full justify-between items-center"
            >
				<span
					class="light:border-[#A9A9A9] mt-2 inline-flex items-center rounded-md border-[0.8px]"
				>
					<button
						class="flex items-center rounded-l-md px-2"
						@click="useCarro.removeFromCarro(producto)"
					>
						-
					</button>
					
					<input
						class="w-[61px] h-[36px] text-[14px] text-center"
						type="text"
						inputmode="numeric"
						:min="0"
						:max="maxCantCompra"
						:value="producto.cantidad"
						@input="useCarro.addToCarro(producto, Number($event.target))"
					>
					<button
						class="flex items-center rounded-r-md px-2"
						@click="useCarro.addToCarro(producto)"
					>
						+
					</button>
				</span>
			
			</div>
			<div class="inline-flex flex-col justify-start text-[12px]">
				<div v-if="!producto?.sugerencia && producto?.stock?.stock" class="mb-0 lg:mb-2 mt-1 text-center text-light-muted">
					{{
						producto?.stock?.stock == 1
							? "Último disponible"
							: `${producto?.stock?.stock} disponibles`
					}}
				</div>

				<template
					v-if="
						producto?.stock?.venta_maxima < producto?.stock?.stock &&
						producto?.stock?.venta_maxima != 0
					"
				>
					<span
						v-if="
							cantidad >= producto.stock.venta_maxima ||
							producto.cantidad == producto.stock.venta_maxima
						"
						class="disponibles font-bold text-[12px]dispo text-light-error"
					>
						Compra maxima: {{ producto.stock.venta_maxima }}
					</span>
				</template>
			</div>
			<hr class="my-1" >
		</div>
        
	</div>
</template>

<script lang="ts" setup>
import { useCarroCompras } from "../../stores/carrocompras";
import type { CarritoItem } from "../../types/stores";

const props = defineProps<{ producto: CarritoItem }>();
defineEmits([ "btnEliminarProducto" ])

const useCarro = useCarroCompras();
const cantidad = ref();

const maxCantCompra = () => {
	if (props.producto?.stock?.venta_maxima == 0) {
		return props.producto?.stock?.stock;
	}
	if (
		props.producto.stock?.stock > 0 &&
		props.producto?.stock?.venta_maxima >= props.producto?.stock?.stock
	) {
		return props.producto?.stock?.stock;
	}
};
const precioProducto = computed(() => {
    if(props.producto.sugerencia) {
        return props.producto.unitario;
    } 
    const precioSugerencias = useCarro.carrito.reduce((acc, item) => {
        if (item.sugerencia && item.productoSugerencia == props.producto.codigo) {
        return acc + Number(item.unitario);
        }
        return acc;
    }, 0);

    return Number(props.producto.unitario) + Number(precioSugerencias)
});
</script>

<style scoped>

button {
	height: 35px;
}

@media (min-width: 1024px) {

	button {
		height: 36px;
	}
}
</style>
