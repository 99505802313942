<template>
	<div class="pb-5">
		<template v-if="loading">
			<div class="min-h-[122px] py-5">
				<the-loading class="mx-auto" size="45px" />
			</div>
		</template>

		<template v-else-if="addedNew">
			<div
				class="flex justify-between mb-5 ps-3 pb-3 border-b border-light-secondary"
			>
				<div class="flex gap-1">
					<svg
						fill="#2bb543"
						height="27px"
						width="27px"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						xml:space="preserve"
					>
						<path
							d="M256,0C114.608,0,0,114.608,0,256s114.608,256,256,256s256-114.608,256-256S397.392,0,256,0z M256,496 C123.664,496,16,388.336,16,256S123.664,16,256,16s240,107.664,240,240S388.336,496,256,496z"
						/>
						<polygon
							points="362.224,155.76 212.016,322.656 148.72,259.36 137.408,270.672 212.64,345.904 374.128,166.464"
						/>
					</svg>
					Agregaste al carrito
				</div>
				<button
					id="carrito-close"
					class="w-[33px] h-[33px]"
					@click="$emit('close')"
				>
					<div class="close-icon" />
				</button>
			</div>
			<hr class="bg-light-secondary" style="height: 0.8px" />
			<nav-carrito-item
				v-if="cartItems.length > 0"
				:producto="cartItems[cartItems.length - 1]"
			/>
		</template>

		<template v-else>
			<template v-if="cartItems.length === 0">
				<div
					class="flex justify-center items-center"
					style="
						height: 156px;
						border-top: 0.3px solid black;
						border-bottom: 0.3px solid black;
						font-size: 20px;
						font-style: italic;
						font-weight: 400;
						line-height: normal;
					"
				>
					-- No hay productos agregados al carro de compras --
				</div>
			</template>
			<template v-else>
				<transition-group name="list" tag="div">
					<template v-for="item in cartItems" :key="item.codigo">
						<template v-if="!item.sugerencia">
							<NavCarritoItem
								:producto="item"
								@btnEliminarProducto="
									(item) =>
										(confirmacionEliminar = { show: true, producto: item })
								"
							/>
							<div
								v-if="cartItems.filter((e) => e.productoSugerencia == item.codigo).length"
								class="text-light-primary px-5 mb-2 italic text-[10px] leading-[11.72px]"
							>
								Componentes agregados:
							</div>
							<NavCarritoSugerencia
								v-for="sugerencia in cartItems.filter(
									(e) => e.productoSugerencia == item.codigo,
								)"
								:producto="sugerencia"
							/>
						</template>
					</template>
				</transition-group>
			</template>
		</template>
		<CustomGeneralDialogConfirm
			v-model.openDialog="confirmacionEliminar.show"
			typeMsg="warning"
			title="¿Está seguro que quiere eliminar el producto?"
			:subtitle="`${confirmacionEliminar?.producto?.nombre}`"
			@confirm="eliminarProducto(confirmacionEliminar.producto)"
			@closeDialog="confirmacionEliminar.show = false"
		/>
	</div>
</template>

<script lang="ts" setup>
import type { CarritoItem } from "../../types/stores";

defineProps<{
	loading: Boolean;
	cartItems: CarritoItem[];
	addedNew: Boolean;
}>();

const emit = defineEmits(["close", "eliminarProducto"]);
const confirmacionEliminar = ref({
	show: false,
	producto: {
		codigo: 0,
		nombre: "",
	},
});
const eliminarProducto = (producto) => {
	emit("eliminarProducto", producto);
	confirmacionEliminar.value.show = false;
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}

.close-icon {
	position: relative;
	width: 24px;
	height: 24px;
	cursor: pointer;
	transition: transform 0.3s ease;
}

.close-icon::before,
.close-icon::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 24px;
	height: 2px;
	background-color: black;
	transition: background-color 0.3s ease;
}

.close-icon::before {
	transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-icon:hover {
	transform: rotate(90deg);
}

.close-icon:hover::before,
.close-icon:hover::after {
	background-color: #31a9fd;
}
</style>
